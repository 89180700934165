import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';

import {UserManagementModule} from "./modules/malco/admin/user-management/user-management.module";
import {PhotoManagementModule} from "./modules/malco/photos/photo-upload/photo-management.module";

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/dashboards/project'
    {path: '', pathMatch : 'full', redirectTo: 'sign-in'},

    // Landing routes
    // {
    //     path: '',
    //     component: LayoutComponent,
    //     data: {
    //         layout: 'empty'
    //     },
    //     children: [
    //         // {path: 'home', loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule)},
    //     ]
    // },
    // Auth routes for guests
    {
        path: '',
        canMatch: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            // {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)},
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)},
            // {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)},
            {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)},
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {path: 'home', loadChildren: () => import('app/modules/redirect/home.module').then(m => m.HomeModule)},
            // for clients
            {path: 'client', children: [
                {path: 'work-order', loadChildren: () => import('app/modules/client/client-work-order/client-work-order.module').then(m => m.ClientWorkOrderModule)},
            ]},

            {path: 'malco', children: [
                    {path: 'work-order-view', loadChildren:() => import('app/modules/malco/work-order-view/work-order-view.module').then(m => m.WorkOrderViewModule)},
                    {path: 'work-order', loadChildren: () => import('app/modules/malco/work-order-management/work-order-management.module').then(m => m.WorkOrderManagementModule)},
                    {path: 'clients', loadChildren: () => import('app/modules/malco/client-management/client-management.module').then(m => m.ClientManagementModule)},
                    {path: 'job-sites', loadChildren: () => import('app/modules/malco/job-site-management/job-site-management.module').then(m => m.JobSiteManagementModule)},
                    {path: 'reports', loadChildren: () => import('app/modules/malco/reports/reports.module').then(m => m.ReportsModule)},
                    {path: 'photos/upload', loadChildren: () => import('app/modules/malco/photos/photo-upload/photo-management.module').then(m => m.PhotoManagementModule)},
                    // for managers
                    // {path: 'manager', children: [
                    //         {path: 'work-order', loadChildren: () => import('app/modules/client/client-work-order/client-work-order.module').then(m => m.ClientWorkOrderModule)},
                    // ]},
                    // for admins
                    {path: 'admin', children: [
                        {path: 'departments', loadChildren: () => import('app/modules/malco/admin/department-management/department-management.module').then(m => m.DepartmentManagementModule)},
                        {path: 'users', loadChildren: () => import('app/modules/malco/admin/user-management/user-management.module').then(m => m.UserManagementModule)},
                        {path: 'equipments', loadChildren: () => import('app/modules/malco/admin/equipment-management/equipment-management.module').then(m => m.EquipmentManagementModule)},
                        {path: 'map', loadChildren: () => import('app/modules/malco/admin/map/map.module').then(m => m.MapModule)},
                    ]},
            ]}
        ]
    },


    // Admin routes
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {path: 'admin', children: [
                {path: 'management', loadChildren: () => import('app/modules/malco/admin/user-management/user-management.module').then(m=> UserManagementModule)},
            ]},

            // Dashboards
            {path: 'dashboards', children: [
                {path: 'project', loadChildren: () => import('app/modules/admin2/dashboards/project/project.module').then(m => m.ProjectModulex)},
                {path: 'analytics', loadChildren: () => import('app/modules/admin2/dashboards/analytics/analytics.module').then(m => m.AnalyticsModule)},
                {path: 'finance', loadChildren: () => import('app/modules/admin2/dashboards/finance/finance.module').then(m => m.FinanceModule)},
                {path: 'crypto', loadChildren: () => import('app/modules/admin2/dashboards/crypto/crypto.module').then(m => m.CryptoModule)},
            ]},

            // Apps
            {path: 'apps', children: [
                {path: 'academy', loadChildren: () => import('app/modules/admin2/apps/academy/academy.module').then(m => m.AcademyModule)},
                {path: 'chat', loadChildren: () => import('app/modules/admin2/apps/chat/chat.module').then(m => m.ChatModule)},
                {path: 'contacts', loadChildren: () => import('app/modules/admin2/apps/contacts/contacts.module').then(m => m.ContactsModule)},
                {path: 'ecommerce', loadChildren: () => import('app/modules/admin2/apps/ecommerce/ecommerce.module').then(m => m.ECommerceModule)},
                {path: 'file-manager', loadChildren: () => import('app/modules/admin2/apps/file-manager/file-manager.module').then(m => m.FileManagerModule)},
                {path: 'help-center', loadChildren: () => import('app/modules/admin2/apps/help-center/help-center.module').then(m => m.HelpCenterModule)},
                {path: 'mailbox', loadChildren: () => import('app/modules/admin2/apps/mailbox/mailbox.module').then(m => m.MailboxModule)},
                {path: 'notes', loadChildren: () => import('app/modules/admin2/apps/notes/notes.module').then(m => m.NotesModule)},
                {path: 'scrumboard', loadChildren: () => import('app/modules/admin2/apps/scrumboard/scrumboard.module').then(m => m.ScrumboardModule)},
                {path: 'tasks', loadChildren: () => import('app/modules/admin2/apps/tasks/tasks.module').then(m => m.TasksModule)},
            ]},

            // Pages
            {path: 'pages', children: [

                // Activities
                {path: 'activities', loadChildren: () => import('app/modules/admin2/pages/activities/activities.module').then(m => m.ActivitiesModule)},

                // Authentication
                {path: 'authentication', loadChildren: () => import('app/modules/admin2/pages/authentication/authentication.module').then(m => m.AuthenticationModule)},

                // Coming Soon
                {path: 'coming-soon', loadChildren: () => import('app/modules/admin2/pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule)},

                // Error
                {path: 'error', children: [
                    {path: '404', loadChildren: () => import('app/modules/admin2/pages/error/error-404/error-404.module').then(m => m.Error404Module)},
                    {path: '500', loadChildren: () => import('app/modules/admin2/pages/error/error-500/error-500.module').then(m => m.Error500Module)}
                ]},

                // Invoice
                {path: 'invoice', children: [
                    {path: 'printable', children: [
                        {path: 'compact', loadChildren: () => import('app/modules/admin2/pages/invoice/printable/compact/compact.module').then(m => m.CompactModule)},
                        {path: 'modern', loadChildren: () => import('app/modules/admin2/pages/invoice/printable/modern/modern.module').then(m => m.ModernModule)}
                    ]}
                ]},

                // Maintenance
                {path: 'maintenance', loadChildren: () => import('app/modules/admin2/pages/maintenance/maintenance.module').then(m => m.MaintenanceModule)},

                // Pricing
                {path: 'pricing', children: [
                    {path: 'modern', loadChildren: () => import('app/modules/admin2/pages/pricing/modern/modern.module').then(m => m.PricingModernModule)},
                    {path: 'simple', loadChildren: () => import('app/modules/admin2/pages/pricing/simple/simple.module').then(m => m.PricingSimpleModule)},
                    {path: 'single', loadChildren: () => import('app/modules/admin2/pages/pricing/single/single.module').then(m => m.PricingSingleModule)},
                    {path: 'table', loadChildren: () => import('app/modules/admin2/pages/pricing/table/table.module').then(m => m.PricingTableModule)}
                ]},

                // Profile
                {path: 'profile', loadChildren: () => import('app/modules/admin2/pages/profile/profile.module').then(m => m.ProfileModule)},

                // Settings
                {path: 'settings', loadChildren: () => import('app/modules/admin2/pages/settings/settings.module').then(m => m.SettingsModule)},
            ]},

            // User Interface
            {path: 'ui', children: [

                // Material Components
                {path: 'material-components', loadChildren: () => import('app/modules/admin2/ui/material-components/material-components.module').then(m => m.MaterialComponentsModule)},

                // Fuse Components
                {path: 'fuse-components', loadChildren: () => import('app/modules/admin2/ui/fuse-components/fuse-components.module').then(m => m.FuseComponentsModule)},

                // Other Components
                {path: 'other-components', loadChildren: () => import('app/modules/admin2/ui/other-components/other-components.module').then(m => m.OtherComponentsModule)},

                // TailwindCSS
                {path: 'tailwindcss', loadChildren: () => import('app/modules/admin2/ui/tailwindcss/tailwindcss.module').then(m => m.TailwindCSSModule)},

                // Advanced Search
                {path: 'advanced-search', loadChildren: () => import('app/modules/admin2/ui/advanced-search/advanced-search.module').then(m => m.AdvancedSearchModule)},

                // Animations
                {path: 'animations', loadChildren: () => import('app/modules/admin2/ui/animations/animations.module').then(m => m.AnimationsModule)},

                 // Cards
                {path: 'cards', loadChildren: () => import('app/modules/admin2/ui/cards/cards.module').then(m => m.CardsModule)},

                // Colors
                {path: 'colors', loadChildren: () => import('app/modules/admin2/ui/colors/colors.module').then(m => m.ColorsModule)},

                // Confirmation Dialog
                {path: 'confirmation-dialog', loadChildren: () => import('app/modules/admin2/ui/confirmation-dialog/confirmation-dialog.module').then(m => m.ConfirmationDialogModule)},

                // Datatable
                {path: 'datatable', loadChildren: () => import('app/modules/admin2/ui/datatable/datatable.module').then(m => m.DatatableModule)},

                // Forms
                {path: 'forms', children: [
                    {path: 'fields', loadChildren: () => import('app/modules/admin2/ui/forms/fields/fields.module').then(m => m.FormsFieldsModule)},
                    {path: 'layouts', loadChildren: () => import('app/modules/admin2/ui/forms/layouts/layouts.module').then(m => m.FormsLayoutsModule)},
                    {path: 'wizards', loadChildren: () => import('app/modules/admin2/ui/forms/wizards/wizards.module').then(m => m.FormsWizardsModule)}
                ]},

                // Icons
                {path: 'icons', loadChildren: () => import('app/modules/admin2/ui/icons/icons.module').then(m => m.IconsModule)},

                // Page Layouts
                {path: 'page-layouts', loadChildren: () => import('app/modules/admin2/ui/page-layouts/page-layouts.module').then(m => m.PageLayoutsModule)},

                // Typography
                {path: 'typography', loadChildren: () => import('app/modules/admin2/ui/typography/typography.module').then(m => m.TypographyModule)}
            ]},

            // Documentation
            {path: 'docs', children: [

                // Changelog
                {path: 'changelog', loadChildren: () => import('app/modules/admin2/docs/changelog/changelog.module').then(m => m.ChangelogModule)},

                // Guides
                {path: 'guides', loadChildren: () => import('app/modules/admin2/docs/guides/guides.module').then(m => m.GuidesModule)}
            ]},

            // 404 & Catch all
            {path: '404-not-found', pathMatch: 'full', loadChildren: () => import('app/modules/admin2/pages/error/error-404/error-404.module').then(m => m.Error404Module)},
            {path: '**', redirectTo: '404-not-found'}
        ]
    }
];
